import Typography from "antd/es/typography/Typography";
import "./privacy.css";
const Privacypolicy = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "10px",
        alignItems: "center",
        direction: "rtl",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography style={{ fontWeight: 700, fontSize: 40 }}>
          Privacy Policy for Al-Mas Online Institute
        </Typography>
      </div>
      <Typography
        style={{ fontWeight: 400, color: "gray ", textAlign: "center" }}
      >
        Last Updated: 22-12-2023
      </Typography>
      <div
        style={{
          overflowY: "scroll",
          width: "50%",
          justifyContent: "center",
          height: "50%",
        }}
      >
        <ol>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Introduction Welcome to Al-Mas Online Institute's Learning
              Management System (LMS). This Privacy Policy is designed to inform
              you about how we collect, use, and protect your personal
              information.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Information We Collect We collect the following types of
              information: User Account Information: To access the LMS, users
              will be provided with account credentials, including a username
              and password. Personal Information: This may include names, email
              addresses, and other contact information. Course and Learning
              Data: Information related to courses taken, progress, and
              assessments. Technical Information: Data such as IP addresses,
              device information, and browser details collected for system
              optimization and security.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              How We Use Your Information We use the collected information for
              the following purposes: Account Management: To manage and
              administer user accounts. Course Delivery: To provide access to
              online classes, track progress, and manage learning materials.
              Communication: To send important announcements, updates, and other
              relevant information. Improvement of Services: To analyze usage
              patterns and improve our LMS system.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Data Security We take appropriate measures to protect your
              personal information. This includes encryption, access controls,
              and regular security assessments.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Third-Party Services We may use third-party services to enhance
              the functionality of our LMS. These services may have their own
              privacy policies, and users are encouraged to review them.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Sharing of Information We do not sell or rent your personal
              information to third parties. However, we may share information
              with trusted partners for purposes such as system maintenance and
              improvement.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Your Choices Users have the right to access, correct, or delete
              their personal information. Please contact us if you wish to
              exercise these rights.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Changes to the Privacy Policy We may update this Privacy Policy
              from time to time. Users will be notified of any significant
              changes.
            </Typography>
          </li>
          <li>
            <Typography style={{ textAlign: "center", fontWeight: 600 }}>
              Contact Information If you have questions or concerns about this
              Privacy Policy, please contact us at
              <a href="mailto:info@aljoud.education"> info@aljoud.education.</a>
            </Typography>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Privacypolicy;
