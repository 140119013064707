import React from "react";
import "./sideBar.scss";
import { Layout, Menu } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Custom component for the first menu item
  const CustomMenuItem1 = () => (
    <div className="custom-menu-item">
      <img src="../../main.svg" />
      <span className="menu-label">الرئيسية</span>
      {localStorage.getItem("role") === "4" ? (
        <Link to="/Dashboard" />
      ) : (
        <Link to="/dashboard" />
      )}
    </div>
  );

  // Custom component for the second menu item
  const CustomMenuItem2 = () => (
    <div
      className="custom-menu-item"
      onClick={() => {
        navigate("/courses");
      }}
    >
      <img src="../../courses.svg" />
      <span className="menu-label">دورات</span>
    </div>
  );

  const CustomMenuItem3 = () => (
    <div className="custom-menu-item">
      <img src="../../award-49 1.svg" />
      {localStorage.getItem("role") === "4" ? (
        <span className="menu-label">شهاداتي</span>
      ) : (
        <span className="menu-label">إنجازاتي</span>
      )}
      <Link to={"/Achievements"}></Link>
    </div>
  );

  const CustomMenuItemForEmpty = () => <div className="custom-menu-item"></div>;
  const CustomMenuItem4 = () => (
    <div className="custom-menu-item">
      <img src="../../notepad.svg" />
      <span className="menu-label">إختباراتي</span>
      <Link to={"/tests"} />
    </div>
  );

  const CustomMenuItem5 = () => (
    <div className="custom-menu-item5">
      <SettingOutlined style={{ fontSize: 20, color: "#A3A3A5" }} />
      <span className="menu-label">الاعدادات</span>
      <Link to={"/profile"} />
    </div>
  );
  const CustomMenuItem6 = () => (
    <div
      className="custom-menu-item5"
      onClick={() => {
        navigate("/");
        localStorage.removeItem("role");
        localStorage.removeItem("FCM");
        localStorage.removeItem("token");
        window.localStorage.removeItem("isLogedIn");
      }}
    >
      <LogoutOutlined style={{ fontSize: 18, color: "#A3A3A5" }} />
      <span className="menu-label" style={{ fontSize: 14 }}>
        تسجيل الخروج
      </span>
    </div>
  );

  return (
    <Sider
      breakpoint="lg"
      collapsedWidth="0"
      onBreakpoint={(broken) => {}}
      onCollapse={(collapsed, type) => {}}
      theme="light"
      style={{ height: "100vh" }}
    >
      <div className="titleHolder">
        {/* <h2 className="sideTitle">LMS</h2> */}
        <img width={"100px"} src={"/logo-blue.png"} />
      </div>
      <Menu
        mode="vertical"
        selectedKeys={[location.pathname.toLowerCase()]}
        theme="light"
      >
        <Menu.Item key="/Dashboard">
          <CustomMenuItem1 />
        </Menu.Item>
        <Menu.Item key="/courses">
          <CustomMenuItem2 />
        </Menu.Item>
        {/* <Menu.Item key="/Achievements">
            <CustomMenuItem3 />
          </Menu.Item> */}
        {localStorage.getItem("role") === "4" && (
          <Menu.Item key="/tests">
            <CustomMenuItem4 />
          </Menu.Item>
        )}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            margin: "10px 8px",
          }}
        >
          <Menu.Item key="/profile">
            <CustomMenuItem5 />
          </Menu.Item>
          <Menu.Item key="/logout">
            <CustomMenuItem6 />
          </Menu.Item>
        </div>
        {/* <Menu.Item className="items" key="empty">
            <CustomMenuItemForEmpty />
          </Menu.Item> */}
      </Menu>
    </Sider>
  );
};

export default SideBar;
