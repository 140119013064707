import { Typography, Button, Input, Result, Form } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import "./Profile.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchTeacherProfile,
  fetchUserProfile,
  editUserProfile,
  updateProfile,
} from "../../redux/slices/profileSlice";
import Home from "../home/Home";
const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.profile.user);
  const loading = useSelector((state) => state.profile.loading);
  const error = useSelector((state) => state.profile.error);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [photo, setPhoto] = useState("");
  const [age, setAge] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [saveButtonLabel, setSaveButtonLabel] = useState("تعديل");
  console.log(error?.message);
  useEffect(() => {
    if (localStorage.getItem("role") == "5") {
      const fetchData = async () => {
        await dispatch(fetchUserProfile());
      };

      fetchData();
    } else if (localStorage.getItem("role") == "4") {
      const fetchData = async () => {
        await dispatch(fetchTeacherProfile());
      };

      fetchData();
    }
  }, [dispatch]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];

    if (file && (file.type === "image/jpeg" || file.type === "image/png")) {
      setSelectedImage(file);

      const reader = new FileReader();
      reader.onload = (e) => {
        setPhoto(e.target.result);
      };
      reader.readAsDataURL(file);
    } else {
      // Handle invalid file type
      console.error("Invalid file type. Please choose a JPEG or PNG image.");
    }
  };
  useEffect(() => {
    if (user && localStorage.getItem("role") == "5") {
      const userData = user.student_info?.users;
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setEmail(userData.email);
      setPhone(userData.phone);
      setPhoto(userData.photo);
      setAge(user.student_info.age);
    } else if (user && localStorage.getItem("role") == "4") {
      const userData = user.teacher_info?.users;
      setFirstName(userData.first_name);
      setLastName(userData.last_name);
      setEmail(userData.email);
      setPhone(userData.phone);
      setPhoto(userData.photo);
      setAge(user.teacher_info.age);
    }
  }, [user]);

  const [selectedImage, setSelectedImage] = useState(null);

  const handleSaveClick = () => {
    if (isEditable && localStorage.getItem("role") == "5") {
      dispatch(
        editUserProfile({
          first_name: firstName,
          photo: selectedImage,
          email: email,
          last_name: lastName,
          web: "true",
          age: age,
          phone: phone,
          _method: "put",
          // Add this line to include the photo
        })
      );

      setSaveButtonLabel("تعديل");
      setIsEditable(false);
    } else if (isEditable && localStorage.getItem("role") == "4") {
      dispatch(
        updateProfile({
          users: {
            first_name: firstName,
            photo: selectedImage,
            email: email,
            last_name: lastName,
          },
          age: age,
          phone: phone,
          bio: "bio",
          qualifications: "qualifications",
          experience: "experience",
          expertise: "expertise",
        })
      );
      // dispatch(fetchTeacherProfile());
    } else {
      setSaveButtonLabel("تعديل");
      setIsEditable(true);
    }
  };

  if (loading) {
    return <Result icon={<LoadingOutlined />} title="جاري التحميل ..." />;
  }

  if (!user) {
    // If there is no user data, display the Result component
    return <Result status="warning" title="لايوجد معلومات للحساب بعد" />;
  }

  return (
    <Home>
      <div className="containerProfile">
        <div className="Settings">
          {user && (
            <Form>
              <div className="setting-title-container">
                <Typography className="setting-title">الاعدادات </Typography>
                <div style={{ width: "10%" }}>
                  <Typography className="setting-kind">الملف الشخصي</Typography>
                </div>
              </div>
              <div className="bottom">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    // border: "1px red solid",
                    height: "100%",
                    gap: 20,
                    paddingBottom: 20,
                    width: "70%",
                  }}
                >
                  <Typography className="personal-title">
                    المعلومات الشخصية
                  </Typography>
                  <Typography className="personal-data">
                    اضف معلوماتك الشخصية
                  </Typography>
                </div>
                <div className="profile-image-container">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 20,
                    }}
                  >
                    <Typography className="image-title">
                      الصورة الشخصية
                    </Typography>
                    <Typography className="image-edit-title">
                      أضف صورتك الشخصة
                    </Typography>
                  </div>
                  <div style={{ position: "relative" }}>
                    {" "}
                    <label
                      htmlFor="imageInput"
                      style={{
                        fontSize: 20,
                        color: "#fff",
                        cursor: "pointer",
                        fontWeight: 700,
                        position: "absolute",
                        bottom: 10,
                        left: 25,
                      }}
                    >
                      تغيير الصورة
                    </label>
                    <input
                      type="file"
                      id="imageInput"
                      accept=".jpeg, .jpg, .png"
                      style={{ display: "none" }}
                      onChange={handleImageChange}
                    />
                    <img
                      src={
                        !photo
                          ? "blank-profile.jpg"
                          : selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : photo?.startsWith("http")
                          ? photo
                          : // : `http://manage.almas.education/public/storage${photo}`
                            `https://admin.aljoud.institute/public/storage${photo}`
                      }
                      alt="profile"
                    />
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 30,
                    paddingTop: 30,
                    justifyContent: "space-between",
                    width: "70%",
                    //  flexShrink: 0,
                    alignContent: "center",
                    borderBottom: " #808191 1px solid",
                  }}
                >
                  <Typography className="name">الاسم</Typography>

                  <Input
                    style={{
                      textAlign: "right",
                      width: "30%",
                      background: "#fff",
                      borderRadius: 16,
                      border: "1px solid #A3A3A5",
                    }}
                    size="large"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={!isEditable}
                  />

                  <Input
                    style={{
                      textAlign: "right",
                      width: "30%",
                      background: "#fff",
                      borderRadius: 16,
                      border: "1px solid #A3A3A5",
                    }}
                    size="large"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={!isEditable}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    paddingBottom: 30,
                    paddingTop: 30,
                    justifyContent: "space-between",
                    width: "70%",
                    //  flexShrink: 0,
                    alignContent: "center",
                    borderBottom: " #808191 1px solid",
                  }}
                >
                  <Typography className="name">البريد الإلكتروني</Typography>
                  <Input
                    style={{
                      textAlign: "right",
                      width: "80%",
                      background: "#fff",
                      borderRadius: 16,
                      border: "1px solid #A3A3A5",
                    }}
                    size="large"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={!isEditable}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 20,
                    padding: 10,
                  }}
                >
                  <Button
                    size="large"
                    style={{
                      width: "10%",
                      backgroundColor: "#FFF",
                      border: "1px solid #A3A3A5",
                      color: "#11142D",
                      borderRadius: 16,
                    }}
                    onClick={() => setIsEditable(false)}
                  >
                    الغاء
                  </Button>

                  <Button
                    type="primary"
                    size="large"
                    style={{
                      width: "10%",
                      color: "#FFF",
                      borderRadius: 16,
                      backgroundColor: "#11142D",
                    }}
                    onClick={handleSaveClick}
                  >
                    {saveButtonLabel}
                  </Button>
                </div>
                {/* <Space direction="" size="middle" style={{ display: "flex" }}>
                  <label className="inputLabel">الاسم</label>
                  <Input
                    style={{ textAlign: "right", width: "100%" }}
                    size="large"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={!isEditable}
                  />

                  <label className="inputLabel">عنوان الحساب</label>
                  <Input
                    style={{ textAlign: "right" }}
                    size="large"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={!isEditable}
                  />
                  <br />
                  <br />
                  <Button
                    size="large"
                    style={{
                      width: "300px",
                      backgroundColor: "#F1F1F1",
                      border: "1px solid #3D5CFF",
                      color: "#3D5CFF",
                    }}
                    onClick={() => setIsEditable(false)}
                  >
                    الغاء
                  </Button>
                </Space> */}
                {/* <Space
                  direction="vertical"
                  size="middle"
                  style={{ display: "flex" }}
                >
                  <label className="inputLabel">العمر</label>
                  <Input
                    style={{ textAlign: "right" }}
                    size="large"
                    value={age}
                    onChange={(e) => setAge(e.target.value)}
                    disabled={!isEditable}
                  />
                  <label className="inputLabel">رقم الموبايل</label>
                  <Input
                    style={{ textAlign: "right" }}
                    size="large"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    disabled={!isEditable}
                  />
                  <br />
                  <br />
                  <Button
                    type="primary"
                    size="large"
                    style={{ width: "300px" }}
                    onClick={handleSaveClick}
                  >
                    {saveButtonLabel}
                  </Button>
                </Space> */}
              </div>
            </Form>
          )}
        </div>
      </div>
    </Home>
  );
};

export default Profile;
