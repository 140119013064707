import "./SingleCuerce.scss";
import { Typography, Button, Empty } from "antd";
import Home from "../home/Home";
import { useNavigate } from "react-router-dom";
import SingleCourseSettings from "../../components/Settings/SingleCourseSettings";
import {
  fetchSingleCourse,
  selectSingleCourse,
} from "../../redux/slices/coursesSlice";
import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import VideoPlayer from "../videoplay/VideoPlayer";

const SingleCuerce = () => {
  const singleCourse_get = useSelector(selectSingleCourse);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  useEffect(() => {
    const loadSingleCourses = async () => {
      await dispatch(fetchSingleCourse(id));
    };

    loadSingleCourses();
  }, [dispatch]);

  const navigate = useNavigate();
  // console.log(singleCourse_get.course.desorption);
  return (
    <Home>
      <div className="containerSingleCuerce">
        <div className="playerSingleCuerce">
          <Typography className="course-title">
            {singleCourse_get?.course?.title}
          </Typography>
          {selectedVideoUrl ? (
            <VideoPlayer id={null} videoUrl={selectedVideoUrl} />
          ) : singleCourse_get?.course?.image ? (
            <img
              className="videoPlayer"
              src={
                singleCourse_get?.course?.image?.startsWith("http")
                  ? singleCourse_get?.course?.image
                  : // : `https://lms.almas.education/public/storage/${singleCourse_get?.course?.image}`
                    `https://admin.aljoud.institute/public/storage/${singleCourse_get?.course?.image}`
              }
              alt=""
            />
          ) : (
            <Empty className="videoPlayer" description={"لا يوجد صورة "} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: 20,
              gap: 10,
            }}
          >
            <Typography className="under-image-title">التفاصيل</Typography>
            <Typography className="under-image-description">
              {singleCourse_get?.course?.description}
            </Typography>
            <div>
              {localStorage.getItem("role") == "4" && (
                <div
                  style={{
                    // margin: 20,
                    padding: "20px 0px 0px 0px ",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    gap: 45,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "#2F3CED",
                      color: "#fff",
                      boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.25)",
                      borderRadius: 16,
                      width: "35%",
                    }}
                    size="large"
                    onClick={() => {
                      navigate(`/addlesson/${id}`);
                    }}
                  >
                    اضافة درس
                  </Button>
                  <Button
                    size="large"
                    style={{
                      color: "#2F3CED",
                      backgroundColor: "#fff",
                      border: "1px solid #2F3CED",
                      borderRadius: 16,
                      width: "35%",
                    }}
                    onClick={() => {
                      if (localStorage.getItem("role") === "4") {
                        navigate(`/NewExam/${id}`);
                      } else if (localStorage.getItem("role") === "5")
                        navigate(`/ExamQuestionType1`);
                    }}
                  >
                    اضافة اختبار
                  </Button>
                  <Button
                    size="large"
                    style={{
                      color: "#2F3CED",
                      backgroundColor: "#fff",
                      border: "1px solid #2F3CED",
                      borderRadius: 16,
                      width: "40%",
                    }}
                    onClick={() => {
                      navigate("/AddLiveLesson");
                    }}
                  >
                    اضافة بث مباشر مجدول
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
        <SingleCourseSettings
          topics={singleCourse_get?.topic}
          onVideoClick={(videoUrl) => setSelectedVideoUrl(videoUrl)}
          // onVideoClick={(videoUrl) => console.log("clicked");}
        />
      </div>
      {/* <VideoPlayer videoUrl={currentVideoUrl}></VideoPlayer> */}
    </Home>
  );
};
export default SingleCuerce;
